<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation :title="$t('message.fgtpwd_title')" icon=""/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <table border="0" align="center" height="100%">
                                <tr>
                                    <td><fa icon="shield" class="align-middle" style="font-size: 120px" /></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-8">
                            <div v-if="errorStatus === '' && isSubmit === false" class="row">
                                <div class="col-12 pb-3">{{ $t('message.fgtpwd_title_desc') }}</div>
                                <div class="col-12 pb-3">
                                    <input v-model="email" class="form-control" :placeholder="$t('message.all_lbl_email')" />
                                </div>

                                <div class="col-12 col-lg-6 pb-2">
                                    <div style="width: 200px; height: 100px">
                                        <img :src="captchaImg" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                        <button class="btn btn-secondary btn-sm mb-2" @click="getCaptcha" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                            <Popper class="popperDark" arrow hover :content="$t('message.all_btn_refresh_captcha')">
                                                <fa icon="refresh" />
                                            </Popper>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 pb-2">
                                    <input v-model="captcha" class="form-control" :placeholder="$t('message.all_lbl_captcha')">
                                </div>

                                <div class="col-lg-12 pb-3">
                                    <div class="pb-2">{{ $t('message.fgtpwd_title_desc_2') }}</div>
                                    <div>{{ $t('message.fgtpwd_title_desc_3') }}</div>
                                </div>
                                <!-- <div class="col-lg-9 pb-3">
                                    An email will be sent to you for reset password, and it is active for 24 hours
                                </div> -->

                                <div class="col-lg-12 pb-3 text-end">
                                    <button type="button" class="btn btn-info text-white" @click="submit">{{ $t('message.all_btn_submit') }}</button>
                                </div>
                            </div>
                            <div v-else-if="errorStatus === '' && isSubmit === true" class="row">
                                <div class="col-lg-12 pb-3">
                                    <div class="pb-2">{{ $t('message.fgtpwd_title_desc_4') }}</div>
                                    <div class="pb-2">{{ $t('message.fgtpwd_title_desc_5') }}</div>
                                    <div>{{ $t('message.fgtpwd_title_desc_6') }}</div>
                                </div>
                            </div>

                            <div v-else class="row">
                                <div v-if="!showResend" class="col-12">
                                    <div class="mb-2">Your account is registered but not activated, please activate your account in order to set your password.</div>
                                    <div>If you do not receive activation email, please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send again, then check your junk/spam folder.</div>
                                </div>
                                <div v-if="showResend" class="col-12">
                                    <div class="row">
                                        <div class="col-12 pb-3">
                                            <div class="col-12 pb-2">{{ $t('message.fgtpwd_desc_resend') }}</div>
                                        </div>
                                        <div class="col-12 pb-3">
                                            <input v-model="emailResend" class="form-control" :placeholder="$t('message.all_lbl_email')">
                                        </div>

                                        <div class="col-12 col-lg-6 pb-3">
                                            <div style="width: 200px; height: 100px">
                                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                                    <Popper class="popperDark" arrow hover :content="$t('message.all_btn_refresh_captcha')">
                                                        <fa icon="refresh" />
                                                    </Popper>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="col-12 col-lg-6 pb-3">
                                            <input v-model="captchaResend" class="form-control" :placeholder="$t('message.all_lbl_captcha')">
                                            <div class="text-end"><button type="button" class="btn btn-info text-white mt-4" @click="resendActivation">{{ $t('message.all_btn_resend') }}</button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="generalContentBox border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-user" style="font-size: 25px"/></td>
                                    <td>
                                        <div>{{ $t('message.fgtpwd_desc_existing_user') }}</div> 
                                        <router-link :to="{ name: 'Login', query: route.query}">
                                            <div class="greyLink"><u>{{ $t('message.all_btn_login') }}</u></div>
                                        </router-link>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="generalContentBox border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-question" style="font-size: 25px"/></td>
                                    <td>
                                        <div>{{ $t('message.all_desc_need_help') }}</div>
                                        <div>{{ $t('message.all_desc_email_to') }}<a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useI18n } from 'vue-i18n'  // use i18n in setup purpose
import Popper from 'vue3-popper'
import funcs from '@/functions/function'

export default {
    name: 'ForgotPassword',
    components: { TopNavigation, Alert, Popper },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId } = useStore()
        const { t } = useI18n() // use i18n in setup purpose

        const email = ref(null)
        const captchaImg = ref(null)
        const refId = ref(null)
        const captcha = ref(null)

        const showResend = ref(false)
        const errorStatus = ref('')
        const emailResend = ref(null)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        const isSubmit = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const submit = () => {
            const p = {
                email: email.value,
                refId: refId.value,
                captcha: captcha.value,
                frontendUrl: '/auth/resetPassword'
            }

            axios.post('/auth/forgotPassword', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        isSubmit.value = true
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: t('message.fgtpwd_alr_reset_pwd_sent')
                        })

                    } else {
                        func.addLog('forgotPassword', 'submit', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'no_permission') {
                                errorStatus.value = res.data.message
                                getCaptchaResend()

                            } else if (res.data.message === 'user_is_online') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_online') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'deleting_user') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_expire') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'empty_email') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.all_alr_vld_email') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'user_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_not_user') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'invalid_whitelabel') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_no_domain') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'activation_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_exceed_limit') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'forgot_password_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_exceed_pwd_limit') + " (" + res.data.status + ")"
                                })

                            } else if (res.data.message === 'password_limit_exceeded') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_exceed_reset_limit') + " (" + res.data.status + ")"
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'submit - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getCaptcha = () => {
            axios.get('/reference/captcha/FORGOTPWD')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImg.value = res.data.data.captcha.image
                        refId.value = res.data.data.refId

                    } else {
                        func.addLog('forgotPassword', 'getCaptcha', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {

                    func.addLog('forgotPassword', 'getCaptcha - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('forgotPassword', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const resendActivation = () => {
            const p = {
                username: emailResend.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 
            
            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: t('message.all_alr_act_sent')
                        })

                    } else {
                        func.addLog('forgotPassword', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'captcha_not_match' || res.data.message === 'ip_not_match' || res.data.message === 'refId_not_found') {
                                getCaptchaResend()
                                captcha.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.all_alr_captcha_unmatch') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'refId_expired') {
                                getCaptchaResend()
                                captcha.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.all_alr_captcha_expire') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'deleting_user') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_expire') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_was_activated') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_actived') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_was_disabled') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_reset_user_disabled') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'activation_limit_exceeded') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.fgtpwd_alr_resend_exceed_limit') + ' (' + res.data.status + ')'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('forgotPassword', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        onMounted(() => {
            getCaptcha()

            if (!(getUserId === null || getUserId === '')) {
                email.value = getUserId.value

            }

            
        })

        return { 
            alert, route, router, closeAlert, 
            captchaImg, captcha, refId, email, submit, getCaptcha, resendActivation,
            showResend, errorStatus, emailResend, getCaptchaResend, captchaImgResend, refIdResend, captchaResend,
            isSubmit
        }
    }
}
</script>

<style>

.greyBox {
  background-color: var(--auth-box-bg);
}

</style>